<template>
  <div>
    <div class="row">
      <div class="col">
        <vs-breadcrumb
        :items="
          [
            {
              title: 'Admin Tag Store',
              url: '/'
            },
            {
              title: 'Politícas del sitio',
              active: true
            }
          ]"
        ></vs-breadcrumb>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <vs-tabs>
          <vs-tab label="Tratamiento de datos">
            <div class="con-tab-ejemplo">
              <div class="row">
                <div class="col">
                  <vue-editor v-model="policies.treatment_data" :editorToolbar="customToolbar"/>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Cookies y privacidad">
            <div class="con-tab-ejemplo">
              <div class="row">
                <div class="col">
                  <vue-editor v-model="policies.cookies_and_privacy" :editorToolbar="customToolbar"/>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Terminos y condiciones">
            <div class="con-tab-ejemplo">
              <div class="row">
                <div class="col">
                  <vue-editor v-model="policies.terms_and_conditions" :editorToolbar="customToolbar"/>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Politícas de cambio">
            <div class="con-tab-ejemplo">
              <div class="row">
                <div class="col">
                  <vue-editor v-model="policies.changes_policies" :editorToolbar="customToolbar"/>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
        <vs-button icon="save" :disabled="loading" class="mt-5" @click="update" color="primary" type="gradient">Actualizar</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import PoliciesService from '@/services/policies.service'
import { VueEditor } from "vue2-editor";
export default {
  components: { VueEditor },
  data: () => ({
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }],
      ["clean"]
    ],
    policies: {
      treatment_data: '',
      cookies_and_privacy: '',
      terms_and_conditions: '',
      changes_policies: ''
    },
    loading: false
  }),
  methods: {
    async getPolicies() {
      this.$vs.loading({
        type: 'point'
      })
      const { data:res } = await PoliciesService.getPolicies()
      this.policies = res.data
      this.$vs.loading.close()
    },
    async update() {
      try {
        this.loading = true
        const { data:res } = await PoliciesService.updatePolicies(this.policies)
        if (res.success) {
          this.$vs.notify({
            title: res.message,
            text:'',
            color: 'success',
            position: 'top-right'
          })
        } else {
          this.$swal('Estamos teniendo problemas', '', 'warning')
        }
        this.loading = false
      } catch (error) {
        this.$swal('Estamos teniendo problemas', '', 'warning')
      }
    }
  },
  created() {
    this.getPolicies()
  }
}
</script>
