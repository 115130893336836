var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vs-breadcrumb',{attrs:{"items":[
          {
            title: 'Admin Tag Store',
            url: '/'
          },
          {
            title: 'Politícas del sitio',
            active: true
          }
        ]}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vs-tabs',[_c('vs-tab',{attrs:{"label":"Tratamiento de datos"}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.policies.treatment_data),callback:function ($$v) {_vm.$set(_vm.policies, "treatment_data", $$v)},expression:"policies.treatment_data"}})],1)])])]),_c('vs-tab',{attrs:{"label":"Cookies y privacidad"}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.policies.cookies_and_privacy),callback:function ($$v) {_vm.$set(_vm.policies, "cookies_and_privacy", $$v)},expression:"policies.cookies_and_privacy"}})],1)])])]),_c('vs-tab',{attrs:{"label":"Terminos y condiciones"}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.policies.terms_and_conditions),callback:function ($$v) {_vm.$set(_vm.policies, "terms_and_conditions", $$v)},expression:"policies.terms_and_conditions"}})],1)])])]),_c('vs-tab',{attrs:{"label":"Politícas de cambio"}},[_c('div',{staticClass:"con-tab-ejemplo"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.policies.changes_policies),callback:function ($$v) {_vm.$set(_vm.policies, "changes_policies", $$v)},expression:"policies.changes_policies"}})],1)])])])],1),_c('vs-button',{staticClass:"mt-5",attrs:{"icon":"save","disabled":_vm.loading,"color":"primary","type":"gradient"},on:{"click":_vm.update}},[_vm._v("Actualizar")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }