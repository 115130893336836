import api from "../api"

class PolicyService {

	static getPolicies() {
		return api({
			method: 'get',
			url: 'policies'
		})
	}

	static updatePolicies(data) {
		return api({
			method: 'put',
			data: data,
			url: 'policies'
		})
	}

}

export default PolicyService
